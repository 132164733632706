<template>
  <div class="login">
    <div class="login-content">
      <div class="park-logo-wrap">
        <div class="logo-wrap">
          <img src="../assets/logo.png" alt=""/>
        </div>
        <span class="park-name">智慧停车系统</span>
      </div>
      <div>
        <van-form @submit="onSubmit">
          <van-field
            v-model="loginForm.phone"
            name="phone"
            label="手机号"
            placeholder="请输入手机号"
            :rules="[{ required: true, validator: validatePhone, message: '请输入正确的手机号' }]"
          />
          <van-field
            v-model="loginForm.password"
            type="password"
            label="密码"
            name="password"
            placeholder="请输入密码"
            :rules="[{ required: true}]"
          />
          <div style="margin: 3rem 0 1rem 0;">
            <van-button round block type="info" native-type="submit">登入</van-button>
          </div>
        </van-form>
        <div style="margin: 0 0 1rem 0;">
          <van-button round block type="info" @click="goReg" native-type="button">注册</van-button>
        </div>
      </div>
      <div class="third-wrap">
        <div class="img-item-wrap" @click="wxlogin">
          <div class="img-item">
            <img src="../assets/wx.png" alt="">
          </div>
          <div class="img-txt">微信登录</div>
        </div>
      </div>

      <div class="support-bottom">
        技术支持：育恩科技
      </div>

      <van-popup v-model="selectParkShow" position="bottom" :style="{ height: '40%' }">
        <van-picker
          show-toolbar
          title=""
          :columns="parkList"
          @confirm="onConfirmPark"
          @cancel="onCancelPark"
        />
      </van-popup>

      <van-dialog v-model="shouldBindSidShow" title="请输入车场编号"
                  :show-cancel-button="false"
                  :show-confirm-button="false"
      >
        <van-form @submit="doBindPark">
          <van-field
            v-model="shouldBindSid"
            name="shouldBindSid"
            label="车场编号"
            placeholder="请输入车场编号"
            :rules="[{ required: true}]"
          />
          <div class="bind-park-comment">
            车场编号请联系管理员
          </div>
          <div style="margin: 2rem 1rem;">
            <van-button round block type="info" native-type="submit">确定</van-button>
          </div>
        </van-form>
      </van-dialog>

      <div v-if="bindAccountForm.show" class="bind-phone-dialog">
        <div class="bind-phone-dialog-title">绑定关联手机号</div>
        <van-form @submit="doBindAccount">
          <van-field
            v-model="bindAccountForm.phone"
            name="phone"
            label="已注册手机号"
            placeholder="请输入已注册手机号"
            :rules="[{ required: true}]"
          />
          <van-field
            v-model="bindAccountForm.pwd"
            name="pwd"
            type="password"
            label="密码"
            placeholder="请输入密码"
            :rules="[{ required: true}]"
          />
          <div style="margin: 3rem 1.6rem;">
            <van-button round block type="info" native-type="submit">下一步</van-button>
          </div>
        </van-form>
      </div>
    </div>
  </div>
</template>

<script>
import popupPicker from '../public/popup-picker'
import * as common from '../api/common'
import * as api from '../api/api'
import * as md5 from 'md5'
// import axios from 'axios'
export default {
  name: 'login',
  components: { 'popup-picker': popupPicker },
  data () {
    return {
      code: '',
      showPicker: false,
      userTypes: common.userTypes,
      loginForm: {
        parkName: '',
        password: '',
        // password: '123456',
        userType: '',
        // phone: '18933937632'
        phone: sessionStorage.getItem('regAccoutPhone') || localStorage.getItem('loginAccoutPhone') || ''
        // parkName: '停车厂',
        // password: '123456',
        // userType: '车主',
        // phone: '18933937632'
      },
      selectParkShow: false,
      parkList: [],
      userInfo: {},
      customFieldName: {
        text: 'park_name',
        values: 'sid'
      },
      stuShow: true,
      shouldBindSid: '',
      shouldBindSidShow: false,
      bindAccountForm: {
        show: false,
        phone: ''
      }
    }
  },
  methods: {
    validatePhone (val) {
      return /^1[3456789]\d{9}$/.test(val);
    },
    translateUserType (type) {
      // if (type == '00010001') {
      //   return common.userTypesTxt.owner
      // } else if (type == '00010002') {
      //   return common.userTypesTxt.park
      // } else if (type == '00010003') {
      //   return common.userTypesTxt.groupCompany
      // } else if (type == '00010004') {
      //   return common.userTypesTxt.agent
      // } else {
      //   return common.userTypesTxt.owner
      // }
      return common.translateUserType(type)
    },
    async getSelfParkList () {
      try {
        let para = {
          ...api.getPublicPara('v2')
        }
        let { code, list } = await api.axiosApiRequests(api.apiUrlList.gzh.get_self_park_list, para)
        if (code === common.resCode.OK && Array.isArray(list)) {
          list.forEach(function (item) {
            item.text = item.park_name
          })
          return list
        } else {
          return {}
        }
      } catch (e) {
        throw e
      }
    },
    doBindPark (values) {
      console.log('values', values)
      let data = {
        sid: values.shouldBindSid,
        ...api.getPublicPara('v2')
      }
      api.axiosApiRequests(api.apiUrlList.platform.user.relevance_park, data)
        .then(res => {
          if (res.code == common.resCode.OK) {
            console.log('this.userInfo', this.userInfo)
            this.doJump(this.userInfo).catch(e => console.log(e))
          } else if (res.code == common.resCode.DataExists) {
            this.$toast('已绑定该sid')
            this.doJump(this.userInfo).catch(e => console.log(e))
          } else if (res.code == 99999) {
            this.$toast.fail(res.msg)
          } else {
            this.$toast.fail('绑定失败')
          }
        })
        .catch(e => {
          console.log(e)
          this.$toast.fail('网络错误')
        })
    },
    onSubmit(values) {
      console.log('submit', values);
      let data = {
        account: this.loginForm.phone,
        pwd: md5(this.loginForm.password),
        ...api.getPublicPara('v2')
      }
      api.axiosApiRequests(api.apiUrlList.login, data)
        .then(res => {
        localStorage.setItem('loginAccoutPhone', this.loginForm.phone)
        if (res.code == common.resCode.OK) {
          localStorage.setItem(common.keyAccessToken, res.access_token)
          this.$store.commit('commitAccessToken', res.access_token)
          this.doJump(res).catch(e => console.log(e))
        } else {
          localStorage.removeItem('userInfo')
          this.$toast.fail('登录失败')
        }
      })
      .catch(e => {
        console.log(e)
        localStorage.removeItem('userInfo')
        this.$toast.fail('网络错误')
      })
    },
    async doJump(data) {
      try {
        sessionStorage.removeItem('regAccoutPhone')
        this.userInfo = data
        let list = await this.getSelfParkList()
        if (Array.isArray(list)) {
          if (list.length === 1) {
            this.selectPark(data, list[0])
            // this.parkList = list
            this.selectParkShow = true
          } else if (list.length > 1) {
            this.parkList = list
            this.selectParkShow = true
            // 显示车场
          } else {
            this.shouldBindSidShow = true
          }
        } else {
          this.$toast('请求错误，请检查网络')
        }
      } catch (e) {
        throw e
      }
    },
    selectPark (data, parkItem) {
      console.log('data, parkItem', data, parkItem)
      console.log('this.userInfo', this.userInfo)
      let info = {
        parkName:  parkItem.park_name,
        userTypeName: this.translateUserType (parkItem.application_type),
        account: data.user_info.user_account
      }
      localStorage.setItem('userInfo', JSON.stringify(info))
      localStorage.setItem('sid', parkItem.sid)
      localStorage.setItem('customer_id', parkItem.customer_id)
      let query = {}
      if (query.user_type_name == common.userTypesTxt.owner) {
        query.user_type_name = info.userTypeName
        query.park_name = info.parkName
        query.account = info.account
        if (common.getProp('test')) {
          pt.query.test = common.getProp('test')
        }
      }
      let pt = {
        path: '/index',
        query: {
          // sid: parkItem.sid,
          ...query
        }
      }

      let localInfo = {
        userTypeName: info.userTypeName,
        parkName: info.parkName,
        account: info.account
      }

      if (common.getProp('test')) {
        localInfo.test = common.getProp('test')
      }

      localStorage.setItem('userInfo', JSON.stringify(localInfo))
      localStorage.setItem('historyPath', JSON.stringify(pt))
      this.$router.replace(pt)
    },

    getUrlCode() { // 截取url中的code方法
      var url = location.search
      this.winUrl = url
      var theRequest = new Object()
      if (url.indexOf("?") != -1) {
        var str = url.substr(1)
        var strs = str.split("&")
        for (var i = 0; i < strs.length; i++) {
          theRequest[strs[i].split("=")[0]] = (strs[i].split("=")[1])
        }
      }
      return theRequest
    },
    wxLoginByCode () {
      this.code = this.getUrlCode().code
      console.log(this.$route.from)
      if (!this.code) return
      let data = {
        code: this.code,
        ...api.getPublicPara('v2')
      }
      api.axiosApiRequests(api.apiUrlList.wx_login, data)
        .then(res => {
        console.log(res)
        if (res && res.code == common.resCode.OK) {
          localStorage.setItem(common.keyAccessToken, res.access_token)
          this.$store.commit('commitAccessToken', res.access_token)
          this.userInfo = res
          if (res.user_info && res.user_info.plat_form_user_id) {
            this.doJump(this.userInfo).catch(e => console.log(e))
          } else {
            this.bindAccountForm.show = true
          }
        } else {
          localStorage.removeItem('userInfo')
          // this.$router.replace('/login')
          this.$toast.fail('登录失败')
        }
      })
        .catch(e => {
          console.log(e)
          localStorage.removeItem('userInfo')
          this.$toast.fail('网络错误')
        })
    },
    wxlogin() {
      // this.code = ''
      this.$toast.loading({
        forbidClick: true,
        duration: 3000
      })
      let host = window.location.host || window.location.hostname
      var local = encodeURIComponent(window.location.protocol + '//' + host + '/#/login')  // 获取页面url
      var appid = 'wx37edf83412262eb3'
      window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${local}&response_type=code&scope=snsapi_userinfo&state=${new Date().getTime()}#wechat_redirect`
    },
    onConfirmPark (value) {
      console.log(value)
      this.selectPark (this.userInfo, value)
    },
    onCancelPark () {
      this.selectParkShow = false
    },
    goReg () {
      // localStorage.clear()
      this.$router.push({
        path: '/reg'
      })
    },
    doBindAccount (form) {
      let para = {
        account: form.phone,
        pwd: md5(form.pwd),
        openid: this.userInfo.user_info.openid
      }
      api.axiosApiRequests(api.apiUrlList.platform.user.bind_wx, para)
        .then(res => {
          console.log(res)
          if (res && res.code == common.resCode.OK) {
            this.doJump({user_info: res}).catch(e => console.log(e))
          } else if (res && res.code == common.resCode.Failed) {
            this.$toast.fail(res.msg)
          } else {
            this.$toast.fail('绑定账号失败')
          }
        })
        .catch(e => {
          console.log(e)
          this.$toast.fail('网络错误')
        })
    }
  },
  mounted () {
    if (localStorage.getItem(common.keyAccessToken)) {
      let history = localStorage.getItem('historyPath')
      if (history) {
        this.$router.push(JSON.parse(history))
        return
      }
    }
    this.wxLoginByCode()
  }
}
</script>

<style scoped lang="scss">
@import "../theme/index";
$bottom-support-span: 1rem;
.login{
  width: 100%;
  height: 100%;
  /*<!--background-color: $global-bg-color;-->*/
  background-image: url("../assets/login-bg.png");
  background-size: 100% 100%;
  .login-content{
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 10vh 10vw $bottom-support-span 10vw;
    .park-logo-wrap{
      flex-shrink: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 3rem;
      .logo-wrap{
        width: 3rem;
        height: 3.8rem;
        & > img{
          width: 100%;
          height: 100%;
        }
      }
      .park-name{
        padding-left: 1rem;
        font-weight: bold;
        font-size: $global-font-size-2X-large;
      }
    }
    .third-wrap{
      display: flex;
      justify-content: center;
      .img-item-wrap{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .img-item{
          width: 40px;
          height: 40px;
          & > img{
            width: 100%;
            height: 100%;
          }
        }
        .img-txt{
          padding-top: 10px;
          font-size: 12px;
        }
      }
    }
    .support-bottom{
      flex-shrink: 0;
      padding: 1rem 0;
      font-weight: bold;
      margin-top: auto;
    }
  }
  .bind-phone-dialog{
    position: fixed;
    z-index: 1500;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: white;
    &-title{
      margin-bottom: 5rem;
      padding-left: 2rem;
      text-align: left;
      font-size: $global-font-size-2X-large;
      font-weight: bold;
    }
  }
  .bind-park-comment{
    text-indent: 1.6rem;
    text-align: left;
    font-size: $global-font-size-small;
    color: $global-theme-gray;
  }
}
</style>
