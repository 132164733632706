<template>
  <div class="popup-picker">
    <van-popup v-model:show="showPicker" round position="bottom">
      <van-picker
        show-toolbar
        :columns="columns"
        @cancel="onCancel"
        @confirm="onConfirm"
      />
    </van-popup>
  </div>
</template>

<script>
export default {
  name: 'popup-picker',
  props: ['value', 'columns', 'showPicker'],
  methods: {
    onConfirm (value) {
      this.$emit('update:value', value)
      this.$emit('update:showPicker', false)
    },
    onCancel () {
      this.$emit('update:value', '')
      this.$emit('update:showPicker', false)
    }
  }
}
</script>

<style scoped lang="scss">
@import "../theme/index";
$bottom-support-span: 10rem;
.login{
  position: relative;
  width: 100%;
  height: 100%;
  background-color: $global-bg-color;
  .login-content{
    box-sizing: border-box;
    height: 100%;
    padding: 10vh 14vw $bottom-support-span 14vw;
    /*background-color: blue;*/
    .park-logo-wrap{
      display: flex;
      align-items: center;
      .logo-wrap{
        width: 3rem;
        height: 3rem;
        & > img{
          width: 100%;
          height: 100%;
        }
      }
      .park-name{
        padding-left: 1rem;
        font-weight: bold;
        font-size: $global-font-size-2X-large;
      }
    }
  }
  .support-bottom{
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 1rem 0;
    font-weight: bold;
    margin-top: auto;
    margin-bottom: 0;
    /*background-color: blue;*/
  }
}
</style>
